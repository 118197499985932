import React from 'react';
import { Trans } from '@lingui/macro';

import { useModalContext } from '../../../hooks/useModal';
import { useFlashLenderFeeQuery } from '../../../hooks/yldr/useFlashLenderFeeQuery';
import { AlertBanner } from '../../../ui-kit/AlertBanner';
import { DEFAULT_SLIPPAGE_OPTIONS } from '../../../ui-config/networksConfig';
import { ScrollModalContent, ScrollModalFooter } from '../../primitives/ScrollModal';
import { DetailsNumberLine, TxModalDetails } from '../FlowCommons/TxModalDetails';
import { PositionModalWrapperProps } from '../FlowCommons/PositionModalWrapper';
import { TxSuccessView } from '../FlowCommons/Success';
import { TxPositionDetails } from '../FlowCommons/TxPositionDetails';
import { SLIPPAGE_WARNING } from '../constants';
import { CompoundFeesActions } from './CompoundFeesActions';
import { SelectSlippage } from '../../../components-yldr/SelectSlippage';
import { BigNumber } from 'bignumber.js';

export const CompoundFeesModalContent = React.memo(
  ({ enrichedPosition, positionAddress, isWrongNetwork }: PositionModalWrapperProps) => {
    const [slippage, setSlippage] = React.useState(DEFAULT_SLIPPAGE_OPTIONS[0]);
    const { gasLimit, txError, mainTxState, setTxError } = useModalContext();
    const { data: compoundCost, isLoading } = useFlashLenderFeeQuery({
      debtToken: enrichedPosition.debtToken,
      amount: enrichedPosition.debtWithoutDecimals,
    });

    const feesCollected = enrichedPosition.token0.marketReferenceCurrencyFee + enrichedPosition.token1.marketReferenceCurrencyFee
    const totalFeesCompounded = feesCollected - compoundCost;

    const deleveragePositionActionsProps = {
      marketId: enrichedPosition.marketId,
      isWrongNetwork,
      positionAddress,
      totalFeesCompounded,
      slippage: BigNumber(slippage).multipliedBy(100).toNumber(),
      noFees: totalFeesCompounded <= 0,
      disabled: enrichedPosition.lowHealthFactor,
    }

    if (mainTxState.success) {
      return (
        <ScrollModalContent>
          <TxSuccessView
            action={<Trans>successfully compounded</Trans>}
            prefix="$"
            amount={mainTxState.value}
          />
        </ScrollModalContent>
      );
    }

    return (
      <>
        <ScrollModalContent>
          <TxPositionDetails token0={enrichedPosition.token0} token1={enrichedPosition.token1} />
          <TxModalDetails
            description={SLIPPAGE_WARNING}
            gasLimit={gasLimit}
            skipLoad={true}
            bottomSlot={(
              <SelectSlippage defaultOptions={DEFAULT_SLIPPAGE_OPTIONS} value={slippage} onChange={setSlippage} />
            )}
          >
            <DetailsNumberLine
              description={<Trans>Fees collected</Trans>}
              value={feesCollected}
              numberPrefix='$'
            />
            <DetailsNumberLine
              description={<Trans>Compound cost</Trans>}
              value={compoundCost}
              numberPrefix="$"
              loading={isLoading}
            />
            <DetailsNumberLine
              description={<Trans>Total fees compounded</Trans>}
              value={totalFeesCompounded}
              numberPrefix="$"
              loading={isLoading}
            />
          </TxModalDetails>

          {enrichedPosition.lowHealthFactor && (
            <AlertBanner
              variant="warning"
              message="Transaction is unavailable due to the low Heath Factor. Please close this position and open a new one."
            />
          )}

          {txError && (
            <AlertBanner variant="error" txError={txError} onClose={() => setTxError(undefined)} />
          )}

        </ScrollModalContent>

        <ScrollModalFooter>
          <CompoundFeesActions {...deleveragePositionActionsProps} />
        </ScrollModalFooter>
      </>
    );
  }
);
